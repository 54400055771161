/* Импорт шрифта через CSS (альтернатива для index.html) */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.app {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  transition: background 1s ease;
  overflow: hidden;
  position: relative;
}

.weather-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

/* Фоны для каждого состояния */
.app.sunny {
  background: linear-gradient(135deg, #ffeb3b, #ff9800);
}

.app.rain {
  background: linear-gradient(135deg, #607d8b, #b0bec5);
}

.app.snow {
  background: linear-gradient(135deg, #e0f7fa, #b3e5fc);
}

.app.freezing-rain {
  background: linear-gradient(135deg, #90a4ae, #cfd8dc);
}

.app.drizzle {
  background: linear-gradient(135deg, #78909c, #b0bec5);
}

.app.thunderstorm {
  background: linear-gradient(135deg, #37474f, #78909c);
}

.app.clouds {
  background: linear-gradient(135deg, #b0bec5, #eceff1);
}

.app.default {
  background: linear-gradient(135deg, #bdbdbd, #e0e0e0);
}

/* Солнце */
.sun {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  background: #fff176;
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(255, 235, 59, 0.8);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { transform: translateX(-50%) scale(1); }
  50% { transform: translateX(-50%) scale(1.1); }
  100% { transform: translateX(-50%) scale(1); }
}

/* Капли дождя */
.rain-drop {
  position: absolute;
  width: 2px;
  height: 20px;
  background: #ffffff;
  opacity: 0.7;
  animation: fall linear infinite;
}

/* Мелкий дождь (drizzle) */
.drizzle-drop {
  position: absolute;
  width: 1px;
  height: 10px;
  background: #ffffff;
  opacity: 0.5;
  animation: fall linear infinite;
}

/* Снежинки */
.snowflake {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 0.8;
  animation: snowfall linear infinite;
}

/* Ледяной дождь */
.freezing-drop {
  position: absolute;
  width: 3px;
  height: 15px;
  background: #bbdefb;
  opacity: 0.6;
  animation: fall linear infinite;
  border-radius: 2px;
}

/* Молнии (thunderstorm) */
.lightning {
  position: absolute;
  width: 2px;
  height: 50px;
  background: #fff59d;
  opacity: 0;
  animation: flash 3s infinite;
}

/* Облака */
.cloud {
  position: absolute;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 50%;
  box-shadow: 
    30px 20px 0 rgba(255, 255, 255, 0.8),
    -25px 15px 0 rgba(255, 255, 255, 0.75),
    15px -10px 0 rgba(255, 255, 255, 0.7),
    -10px 25px 0 rgba(255, 255, 255, 0.65);
  filter: blur(10px);
  opacity: 0.6;
  animation: float linear infinite;
}

/* Анимации */
@keyframes fall {
  0% { transform: translateY(-100vh); opacity: 0.7; }
  100% { transform: translateY(100vh); opacity: 0; }
}

@keyframes snowfall {
  0% { transform: translateY(-100vh) translateX(0); opacity: 0.8; }
  100% { transform: translateY(100vh) translateX(50px); opacity: 0; }
}

@keyframes flash {
  0% { opacity: 0; }
  5% { opacity: 1; }
  10% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes float {
  0% { transform: translateX(-100vw) translateY(0); }
  50% { transform: translateX(0) translateY(-10px); }
  100% { transform: translateX(100vw) translateY(0); }
}

/* Информация (температура, статус и координаты) */
.info {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  color: #ffffff;
  font-family: Arial, sans-serif;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  text-align: right;
}

.temperature {
  font-size: 36px;
}

.status {
  font-size: 24px;
  margin-top: 10px;
}

.coordinates {
  font-size: 20px;
  margin-top: 10px;
}

/* Плавное появление */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Загрузочный экран */
.loading-screen {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #eceff1;
  color: #333;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #b0bec5;
  border-top: 5px solid #37474f;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-screen p {
  margin-top: 20px;
  font-size: 24px; /* Увеличиваем шрифт */
  font-family: 'Montserrat', sans-serif; /* Применяем красивый шрифт */
  font-weight: 700; /* Делаем текст жирным для стиля */
  letter-spacing: 1px; /* Добавляем лёгкий разряд между буквами */
}